import React, { useState } from "react"
import { useDeleteApplicationMutation } from "../../app/api/service"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { Application } from "../../types/application"
import { useNavigate } from "react-router-dom"

export function ApplicationDeleteConfirmationDialog(props: {
  app: Application
}) {
  const [open, setOpen] = useState(false)
  const [deleteApp, { isLoading }] = useDeleteApplicationMutation()
  const navigate = useNavigate()

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={"error"}
        variant={"contained"}
      >
        Delete Application
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Delete Application</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? You can't undo this action afterwards.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant={"contained"}
            autoFocus
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={async () => {
              await deleteApp(props.app.id)
              navigate("/applications")
              setOpen(false)
            }}
            variant={"contained"}
            color={"error"}
            loading={isLoading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
