import React, { useCallback, useMemo } from "react"
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useSearchParams } from "react-router-dom"
import { OAuthParams, OAuthResponse } from "../../types/oauth"
import { ErrorOutlineTwoTone, LinkRounded } from "@mui/icons-material"
import { CutPaper } from "../../components/paper/CutPaper"
import {
  useAuthorizeMutation,
  useOauthReviewQuery,
} from "../../app/api/service"
import { BaseResponse } from "../../types/user"

export function OAuthErrorArea(props: { message: string }) {
  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack
          spacing={1}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant={"h5"}>{props.message}</Typography>
          <ErrorOutlineTwoTone style={{ fontSize: 80 }} />
        </Stack>
      </CutPaper>
    </Grid>
  )
}

const convertParams = (searchParams: URLSearchParams) => {
  return Object.fromEntries([...searchParams])
}

export function OAuth() {
  const [params] = useSearchParams()
  const convertedParams = useMemo(
    () => convertParams(params) as unknown as OAuthParams,
    [params],
  )

  const { data: app, error: appError } = useOauthReviewQuery(convertedParams)

  if (appError) {
    const errorResponse = appError as unknown as { data: BaseResponse<string> }
    if (errorResponse.data.status === 404) {
      return <OAuthErrorArea message={"Invalid application"} />
    }
    return <OAuthErrorArea message={errorResponse.data.payload} />
  }

  return app ? <OAuthArea app={app.payload} /> : null
}

export function OAuthArea(props: { app: OAuthResponse }) {
  const { app } = props

  const redirect_uri = useMemo(() => app.redirect_uri, [app])

  const [authorize, { isLoading }] = useAuthorizeMutation()

  const callback = useCallback(() => {
    authorize(app)
      .unwrap()
      .then((result) => {
        const redirect_uri = new URL(app.redirect_uri)
        redirect_uri.searchParams.append("code", result.payload.code)
        redirect_uri.searchParams.append("state", app.state)

        window.location.href = redirect_uri.toString()
      })
  }, [app, authorize])

  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant="h5">Authorize with {app.app_name}</Typography>
          <Divider />
          <Stack spacing={1} direction={"column"}>
            <List subheader={"Requested Permissions"} dense disablePadding>
              {app.scopes.map((s) => {
                let scopeEntry = s as string
                switch (s) {
                  case "get_user":
                    scopeEntry =
                      "Get your basic user profile including RSI handle"
                    break
                  case "get_discord":
                    scopeEntry = "View your Discord profile details"
                    break
                }

                return (
                  <ListItem key={s}>
                    <ListItemText>{scopeEntry}</ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Stack>

          <Divider />

          <List dense disablePadding>
            <ListItem>
              <ListItemIcon>
                <LinkRounded />
              </ListItemIcon>
              <ListItemText>
                Once you are authorized, you will be redirected to{" "}
                {redirect_uri}
              </ListItemText>
            </ListItem>
          </List>

          <Divider />

          <LoadingButton disabled={!app} loading={isLoading} onClick={callback}>
            Authorize
          </LoadingButton>
        </Stack>
      </CutPaper>
    </Grid>
  )
}

export function OAuthSkeleton() {
  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack spacing={1}>
          <Skeleton variant={"rectangular"} height={400} />
        </Stack>
      </CutPaper>
    </Grid>
  )
}
